

import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import CardMembers from "@/components/boards/cardModal/CardMembers.vue";
import CardDesc from "@/components/boards/cardModal/CardDesc.vue";
import CardActivity from "@/components/boards/cardModal/CardActivity.vue";
import CheckList from "@/components/boards/CheckList.vue";
import CardAttachment from "@/components/boards/cardModal/CardAttachment.vue";
import axios from "axios";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { mapGetters } from "vuex";

@Component({
  components: {
    CardMembers,
    CardDesc,
    CardActivity,
    CheckList,
    CardAttachment,
    DatePicker
  },
  computed: {
    ...mapGetters(["isAdmin"]),
  }
})
export default class BoardCardModalContent extends Vue {
  @Prop(Object) card: any;
  
  users: any = [];
  created() {
    this.loadData();
    if(this.card.estimatedCompletionDate)
  {
    this.card.estimatedCompletionDate=new Date(this.card.estimatedCompletionDate).toISOString().split("T")[0];
  }
  }

  loadData() {
    try {
      const baseURI = 'api/GetUsers/' + this.card.groupId;
      axios.get(baseURI)
        .then((response) => {
          this.users = response.data;
          console.log(this.users);
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error)
    // eslint-disable-next-line no-empty
    {
    }
  }
  formatDate(dateString: string): string {
  const date = new Date(dateString);
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  
  const day = date.getDate().toString().padStart(2, "0");
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  return `${day} ${month} ${year}, ${hours}:${minutes}`;
}


  @Watch('card', { deep: true })
  onCardhanged(value: any, oldValue: any) {

    axios.post('api/updateFulllTask', this.card)
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  handleDateChange() {
    axios.post('api/updateFulllTask', this.card)
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
    }
}
