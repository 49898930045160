
import ModalContent from "@/components/boards/cardModal/ModalContent.vue";
import CardTitle from "@/components/boards/cardModal/CardTitle.vue";
import CheckList from "@/components/boards/CheckList.vue";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component({
  components: {
    ModalContent,
    CardTitle,
    CheckList,
  },
})
export default class BoardCard extends Vue {
  @Prop(Object) card: any;
  @Prop(String) listTitle!: string;
  @Prop(Number) level!: number;
  isDetailsOpen = false;
  total = 0;
  completed = 0;

  @Watch("card.subTasks", { deep: true })
  onCheckJsonChanged(value: any[], oldValue: any[]) {
    //debugger;
    if (this.card.subTasks) {
      this.completed = this.card.subTasks.filter((value: { checked: any }) => value.checked).length;
      this.total = this.card.subTasks.length;
  
    }
  }
  toggelCheckList() {
    this.isDetailsOpen = !this.isDetailsOpen;
  }
  created() {
    if (this.card.subTasks) {
      this.completed = this.card.subTasks.filter((value: { checked: any }) => value.checked).length;
      this.total = this.card.subTasks.length;
    }
  }
  showModal() {
    this.$bvModal.show(`card-${this.card.id}`);
  }

  getClass(level: number): string {
    return `priority-${level}`;
  }
}
